body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

// Snap for cover page; source: https://stackoverflow.com/a/69280441
.snap {
  width: 100%;
  height: calc(100vh - 127px);
  scroll-snap-points-y: repeat(300px);
  scroll-snap-type: y mandatory;
  scroll-snap-type: mandatory;
  overflow: auto;
}

.snap section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 127px);
  scroll-snap-align: start;
}

// Background image source: https://www.pngegg.com/en/png-dxdzt
// CSS code source: https://css-tricks.com/apply-a-filter-to-a-background-image/
.main-section {
  position: relative;
}

.main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/main_background.png");
  background-size: contain;
  filter: hue-rotate(294deg) opacity(50%) brightness(2);
}

.main-section-inside {
  /* This will make it stack on top of the ::before */
  position: relative;
  max-width: 550px;
  text-shadow: 1px 1px 2px #686868;
}

// Flex row
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// Flex column center
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Diagram image on the homepage
.diagram-image {
  max-width: 500px;
}

// Icon image formatting
.icon-image {
  width: 75px;
}

// Invert if it is in dark mode
.icon-image-dark {
  filter: invert(1);
}

// Increase text size on frontpage
.larger-text {
  font-size: 110%;
}

// Increase text size for large icon
.large-icon {
  font-size: 300%;
  color: darkgrey;
  text-shadow: 1px 1px 2px #686868;
  filter: opacity(1) !important;
}